/// <reference path="../../../node_modules/@types/requirejs/index.d.ts" />

import jQuery from 'jquery';
import loadScript from '../../util/loadScript';
import { bind as bindTree } from '../tree/Tree';
import { KonfigExports } from '../../areas/main/index';
import { settings } from '../../areas/main/config';
import './style.less';
import appUrl from '../../util/appUrl';
import { ObjectForm } from '../form/model';

declare global {
	interface Window {
		//to prevent webpack processing Monaco's loader calls, we must
		//explicity target the require function of global/window
		require: typeof require
	}
}

export type CodeEditorType = 'Expression' | 'InitialValue' | 'Sql';

export interface ICodeEditorOptions {
	value?: string;
	title?: string;
	type?: CodeEditorType;
	form?: ObjectForm;
	aliases?: string;
	readOnly?: boolean;
	onShow?: () => void;
	onAccept?: (value: string) => void;
}

export default function codeEditor(options: ICodeEditorOptions): void {
	if (window !== window.top) {
		const exports = window.top['konfig'] as KonfigExports;
		return exports.codeEditor(options);
	}

	function showEditor(editor: CodeEditor) {
		editor.show(options);
	}

	if (CodeEditor.instance) {
		showEditor(CodeEditor.instance);
	}
	else {
		const elementId = "k-codeeditor";
		let container = jQuery(`#${elementId}`);

		if (!container.length) {
			container = jQuery(`<div id='${elementId}'></div>`).prependTo("body");
		}

		const vsPath = appUrl(settings.monacoPath) + "/vs";

		container.load(appUrl('~/CodeEditor'), () => {
			loadScript(`${vsPath}/loader.js`)
				.then(() => {

					window.require.config({
						paths: {
							'vs': vsPath
						}
					});

					window.require(['vs/editor/editor.main'],
						() => {
							monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
								noSemanticValidation: true,
								noSyntaxValidation: true
							});

							let editor = CodeEditor.instance = new CodeEditor(jQuery(".code-editor", container));
							showEditor(editor);
						});
				})
				.catch(error => { throw error });
		});
	}
}

class CodeEditor {
	static instance: CodeEditor;

	constructor(private element: JQuery) {
		element
			.modal({
				backdrop: false,
				show: false
			})
			.on("shown.bs.modal", () => {
				const bootstrapModalZindex = 1050;
				const depth = jQuery(".modal.in").length - 1;
				element.css("z-index", bootstrapModalZindex + depth * 10);

				const onShow = this.options.onShow;
				onShow && onShow();
			});

		const container = jQuery(".monaco-container", element)[0];
		this.editor = monaco.editor.create(container, this.settings(this.options));

		jQuery(".btn-ok", element).click(() => {
			const accept = this.options.onAccept;
			if (accept) {
				const value = this.editor.getValue();
				accept(value);
			}

			this.element.modal("hide");
		});

		jQuery(".fields-container", element).on("click", ".item-text", e => {
			e.stopImmediatePropagation();

			const treeItem = jQuery(e.currentTarget).closest('.k-in');
			const expression = treeItem.find('input.node-value').val() as string;

			if (!expression) {
				return;
			}

			const editor = this.editor;
			const selection = editor.getSelection();

			editor.pushUndoStop();
			editor.executeEdits("field-click", [
				{
					range: selection,
					text: expression,
					forceMoveMarkers: true
				}
			]);

			editor.pushUndoStop();
			editor.focus();
		});
	}

	show(options: ICodeEditorOptions) {
		this.element
			.toggleClass('read-only', options.readOnly)
			.find("header.main label").text(options.title);

		const updateOptions =
			options.type !== this.options.type
			|| options.readOnly !== this.options.readOnly;

		if (updateOptions) {
			this.editor.updateOptions(this.settings(options));
		}

		this.editor.setValue(options.value);

		const showModal = () => {
			this.options = options;
			this.element.modal('show');
		}

		const tryGetId = (name: string): number => {
			const field = options.form?.findField(name);
			if (field) {
				const value = field.value();

				if (value && value.id) {
					return value.id;
				}

				return value;
			}

			return null;
		}

		const formId = tryGetId("FormId");
		const entityId = tryGetId("EntityId");

		const fields = jQuery(".fields-container", this.element);
		const showFields = options.type !== 'Sql' && options.readOnly !== true;

		const attrDiffers = (name: string, value: number) => fields.attr(name) !== value + '';

		const loadFields = showFields
			? attrDiffers('data-form', formId) || attrDiffers('data-entity', entityId)
			: false;

		fields.toggleClass('hide', !showFields);

		if (loadFields) {
			const aliases = options.aliases
				? `aliases=${encodeURIComponent(options.aliases)}`
				: '';

			const contentURL = formId
				? `~/CodeEditor/Fields?formId=${formId}&${aliases}`
				: entityId
					? `~/CodeEditor/Fields?entityId=${entityId}&${aliases}`
					: `~/CodeEditor/Functions/?${aliases}`;

			fields
				.attr('data-form', formId)
				.attr('data-entity', entityId)
				.load(appUrl(contentURL), () => {
					bindTree(fields);
				});
		}

		showModal();
	}

	settings(options: ICodeEditorOptions): monaco.editor.IStandaloneEditorConstructionOptions {
		let settings: monaco.editor.IStandaloneEditorConstructionOptions = {
			automaticLayout: true,
			scrollBeyondLastLine: false,
			readOnly: options.readOnly,
			minimap: {
				enabled: false
			}
		};

		if (options.type === 'Sql') {
			settings.language = 'sql';
			settings.theme = 'vs-dark';
			settings.wordWrap = 'on';
			settings.mouseWheelZoom = true;
		}
		else {
			settings.language = 'javascript';
		}

		return settings;
	}

	editor: monaco.editor.IStandaloneCodeEditor;
	options: ICodeEditorOptions = {};
}
